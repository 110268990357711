<template>
  <div class="home">
    <div class="rco-box">
      <!-- <img src="~@/assets/img/images/about-banner.png" alt="" /> -->
      <img
        src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/about-banner.png"
        alt=""
      />
      <div class="container-box dv-position">关于我们</div>
    </div>
    <div class="container-box introduce">
      <div class="left-img">
        <!-- <img src="~@/assets/img/images/tower.png" alt="" /> -->
        <img
          src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/tower.png"
          alt=""
        />
      </div>
      <div class="right-box">
        <p class="pdb">
          视联方青（广州）医疗科技有限公司成立于2019年，是一家专注于病理人工智能及解决方案的企业，研发团队来自中山大学、华南理工大学、南开大学、北亚利桑那大学等高级院校计算机专业人才。是锟元方青公司姊妹公司，也是锟元方青公司独家网络推广授权商。锟元方青是一家由病理专家丁彦青教授创始和主导的临床病理诊断人工智能项目研发公司，除了拥有强大的特聘顾问团队外，参与项目研发的病理专家团队主要依托南方医科大学病理学系及南方医院病理科，宫颈细胞学玻片扫描分析系统已获国家二类医疗器械注册证。
        </p>
        <p class="pdb">
          针对我国病理科在实际运作中存在的问题，搭建远程医疗协同服务平台，服务包括：远程病理诊断、细胞学病理AI辅助诊断、远程病理质控、远程会诊、远程培训、远程胎心监护、医生社区、健康宣教等；通过协同平台推广病理AI计算机辅助诊断产品，同时向基层医院提供远程医疗协同服务。
        </p>
        <p>
          公司是广东省基层医药学会提高基层水平，开展远程医疗，落实分级诊疗的紧密协作单位。
        </p>
        <div class="introduce-position">
          <div class="dv-item">
            <img src="~@/assets/img/images/about1.png" alt="" />
            <span class="sp1"><i>2019</i>成立</span>
          </div>
          <div class="dv-item">
            <img src="~@/assets/img/images/about2.png" alt="" />
            <span class="sp2">高校人才</span>
          </div>
          <div class="dv-item">
            <img src="~@/assets/img/images/about3.png" alt="" />
            <span class="sp1"><i>AI</i>计算机辅助诊断</span>
          </div>
        </div>
      </div>
    </div>
    <IntellectualProperty />
    <MapView />
    <Footer />
  </div>
</template>

<script>
import TopBg from "@/components/TopBg.vue";
import IntellectualProperty from "@/components/IntellectualProperty.vue";
import MapView from "@/components/MapView.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "RemoteCollaborative",
  components: {
    TopBg,
    IntellectualProperty,
    MapView,
    Footer
  },
  data() {
    return {};
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.rco-box {
  margin-top: 64px;
  width: 100%;
  display: flex;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
}
.dv-position {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  font-size: 46px;
  color: #fff;
}
.introduce {
  margin-top: 64px;
  display: flex;
}
.left-img {
  width: 230px;
  height: 420px;
  margin-right: 56px;
  img {
    width: 100%;
    height: 100%;
  }
}
.right-box {
  height: 420px;
  padding-right: 56px;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  p {
    font-size: 16px;
    line-height: 26px;
    color: #262626;
  }
  .pdb {
    padding-bottom: 30px;
  }
  .introduce-position {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    .dv-item {
      width: 144px;
      height: 100px;
      background: #f0f0f0;
      margin-right: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 24px;
      color: #262626;
      img {
        margin-bottom: 10px;
        width: 32px;
        height: 32px;
      }
      .sp1 {
        vertical-align: middle;
        font-size: 12px;
        i {
          font-size: 24px;
          vertical-align: middle;
        }
      }
      .sp2 {
        font-size: 16px;
      }
    }
  }
}
</style>
