<template>
  <div class="container-box martb">
    <div class="title-font">知识产权体系</div>
    <swiper
      :options="swiperOption"
      ref="mySwiper"
      class="swiper-wrapper"
      v-viewer
    >
      <swiper-slide class="swiper-slide" v-for="item in imgList" :key="item.id">
        <img class="img" :src="item.icon" alt="" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          icon: require("../assets/img/images/z1.png")
        },
        {
          id: 2,
          icon: require("../assets/img/images/z2.png")
        },
        {
          id: 3,
          icon: require("../assets/img/images/z3.png")
        },
        {
          id: 4,
          icon: require("../assets/img/images/z4.png")
        },
        {
          id: 5,
          icon: require("../assets/img/images/z5.png")
        },
        {
          id: 6,
          icon: require("../assets/img/images/z6.png")
        },
        {
          id: 7,
          icon: require("../assets/img/images/z7.png")
        },
        {
          id: 8,
          icon: require("../assets/img/images/z8.png")
        },
        {
          id: 9,
          icon: require("../assets/img/images/z9.png")
        }
      ],
      swiperOption: {
        loop: true, // 循环
        speed: 500, //切换速度
        mousewheelControl: false, // 禁止鼠标滚轮切换
        autoplay: {
          delay: 1000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        watchSlidesProgress: true,
        centeredSlides: true,
        spaceBetween: 10,
        slidesPerView: 5,
        loopedSlides: 2,
        observer: true,
        observeParents: true
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.martb {
  margin-top: 115px;
  margin-bottom: 64px;
}
.swiper-box {
  margin-top: 18px;
}
.img {
  width: 100px;
  height: 100px;
}
.swiper-wrapper {
  cursor: pointer;
  margin-top: 30px;
}
.swiper-slide {
  transition: 1s;
  transform: scale(0.85);
  img {
    width: 100%;
    height: 100%;
    padding: 30px 0;
  }
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1.1);
}
</style>
