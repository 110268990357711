<template>
  <div class="map-view">
    <!-- <img src="~@/assets/img/images/map2.png" alt="" /> -->
    <img
      src="https://platform-1309634311.cos.ap-shanghai.myqcloud.com/public/website/map2.png"
      alt=""
    />
    <div class="apply-view">
      <div class="title">申请免费试用</div>
      <Form
        ref="formInline"
        :model="formInline"
        :rules="ruleInline"
        :label-width="90"
        inline
      >
        <FormItem prop="name" label="姓名">
          <Input
            type="text"
            v-model="formInline.name"
            placeholder="请输入姓名"
            clearable
            :maxlength="30"
          >
          </Input>
        </FormItem>

        <FormItem prop="phone" label="手机号">
          <Input
            type="text"
            v-model="formInline.phone"
            placeholder="请输入手机号"
            clearable
            :maxlength="11"
          >
          </Input>
        </FormItem>

        <FormItem prop="orgName" label="机构名称">
          <Input
            type="text"
            v-model="formInline.orgName"
            placeholder="请输入机构名称"
            clearable
            :maxlength="50"
          >
          </Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit('formInline')"
            >提交申请</Button
          >
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const validatePhone = (rule, value, callback) => {
      let isPhone = /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
      console.log(value);
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (!isPhone.test(value)) {
        callback(new Error("请输入正确手机号码"));
      } else {
        callback();
      }
    };
    const validateOrg = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入机构名称"));
      } else {
        callback();
      }
    };
    return {
      modal: false,
      formInline: {
        name: "",
        phone: "",
        orgName: ""
      },
      ruleInline: {
        name: [
          {
            required: false,
            // validator: validateName,
            trigger: "blur"
          }
        ],
        phone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur"
          }
        ],
        orgName: [
          {
            required: true,
            validator: validateOrg,
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    handleSubmit(name) {
      // this.$Message.error("申请失败！请重新操作");
      this.$refs[name].validate(valid => {
        console.log(valid);
        console.log(this.formInline);
        if (valid) {
          this.$axios
            .post(
              "http://dev.aidoctors.com.cn:9993/site/apply/trial",
              this.formInline
            )
            .then(res => {
              console.log(res);
              if (res.data.code == 0) {
                this.$refs[name].resetFields();
                const title = "申请成功";
                const content = "<p>24小时内会有客服人员联系您</p>";
                this.$Modal.success({
                  title: title,
                  content: content
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.map-view {
  margin-top: 80px;
  width: 100%;
  // height: 364px;
  // background: url("~@/assets/img/images/map.png") no-repeat;
  // background-size: 100% 100%;
  position: relative;
  img {
    width: 100%;
    height: auto;
    min-height: 300px;
  }
  .apply-view {
    box-shadow: 0px 0px 10px 15px #ddd;
    .title {
      font-size: 26px;
      font-weight: 600;
      color: #262626;
      line-height: 42px;
      margin-bottom: 24px;
      text-align: center;
    }
    background: #fff;
    width: 1120px;
    padding: 0 24px;
    padding-top: 24px;
    padding-bottom: 12px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
