<template>
  <div class="box-backgroud">
    <swiper :options="swiperOption" ref="mySwiper" class="home-bg">
      <swiper-slide v-for="item in imgList" :key="item.id">
        <img :src="item.icon" alt="" />
        <div class="container-box marker-box">
          <div class="banner-title">
            <span class="span1">{{ item.title1 }}</span>
            <span class="span2">{{ item.title2 }}</span>
          </div>
          <p style="width:40%;">{{ item.text }}</p>
        </div>
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div> -->
    </swiper>
  </div>
</template>
<script>
export default {
  props: {
    imgList: Array
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        effect: "fade",
        fade: {
          crossFade: false
        },
        // spaceBetween: -45,
        // autoplay: {
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false,
        //   delay: 2000
        // },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          slideChange: () => {}
        }
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.box-backgroud {
  width: 100%;
  // background: #1e4ea0;
  display: flex;
  justify-content: center;
  margin-top: 64px;
}
.home-bg {
  width: 100%;
  height: auto;
  position: relative;
  // background: url("~@/assets/img/home/home-bg.png") no-repeat;
  // background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .pad {
    padding-left: 42px;
    .title-dv {
      padding-top: 166px;
      padding-bottom: 10px;
      font-size: 36px;
      color: #fff;
    }
    .p1 {
      font-size: 16px;
      color: #fff;
      line-height: 20px;
    }
    .dv-btn {
      width: 180px;
      height: 48px;
      font-size: 22px;
      color: #1e4ea0;
      background: #fff;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      margin-top: 100px;
      cursor: pointer;
    }
  }
}
.marker-box {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  .banner-title {
    font-size: 46px;
    line-height: 65px;
  }
  .span1 {
    color: #007aff;
    padding-right: 20px;
  }
  .span2 {
    color: #35c800;
  }
  p {
    padding-top: 10px;
    line-height: 26px;
    color: #262626;
    font-size: 16px;
  }
}
// @media screen and (max-width: 1680px) {
//   .home-bg,
//   .home-bg img {
//     width: 1680px;
//     height: 525px;
//   }
// }

// @media screen and (max-width: 1600px) {
//   .home-bg,
//   .home-bg img {
//     width: 1600px;
//     height: 500px;
//   }
// }

// @media screen and (max-width: 1580px) {
//   .home-bg,
//   .home-bg img {
//     width: 1580px;
//     height: 493.75px;
//   }
// }

// @media screen and (max-width: 1480px) {
//   .home-bg,
//   .home-bg img {
//     width: 1480px;
//     height: 462.5px;
//   }
// }

// @media screen and (max-width: 1380px) {
//   .home-bg,
//   .home-bg img {
//     width: 1380px;
//     height: 431.25px;
//   }
// }
</style>
