<template>
  <div class="footer-box">
    <div class="container-box footer-group">
      <div class="dv-top">
        <div class="dv2-box">
          <div class="text-box">
            <div class="footer-logo-img">
              <img src="~@/assets/img/images/footer-logo.png" alt="" />
            </div>
          </div>
          <div class="text-box" style="margin-left:65px;">
            <p class="title-p">快速导航</p>
            <p
              class="title-p1 title-pdt a-link"
              @click="toLink({ path: '/', name: 'Home' })"
            >
              首页
            </p>
            <p
              class="title-p1 title-pdt a-link"
              @click="
                toLink({
                  path: '/remoteCollaborative',
                  name: 'RemoteCollaborative'
                })
              "
            >
              远程协同平台
            </p>
            <p
              class="title-p1 title-pdt a-link"
              @click="toLink({ path: '/solution', name: 'Solution' })"
            >
              解决方案
            </p>
            <p
              class="title-p1 a-link"
              @click="toLink({ path: '/about', name: 'About' })"
            >
              关于我们
            </p>
          </div>

          <div class="text-box" style="margin-left:55px;">
            <p class="title-p">市场合作</p>
            <p class="title-p1 title-pdt">
              联系我们：<span style="font-size:36px;">020-8423 6325</span>
            </p>
            <p class="title-p1 title-pdt">
              邮箱：slfq@aidoctors.com.cn
            </p>
            <p class="title-p1 title-pdt">工作时间：周一至周五09:00-17:30</p>
            <p class="title-p1">
              公司地址：广州市越秀区北京路3号港汇大厦13层A单元
            </p>
          </div>
        </div>

        <div class="dv1-box">
          <div class="dv-item dv-item-mar">
            <div class="dvimg">
              <img src="~@/assets/img/images/qrcode1.png" alt="" />
            </div>
            <span>视联方青公众号</span>
          </div>
          <!-- <div class="dv-item">
            <div class="dvimg">
              <img src="" alt="" />
            </div>
            <span>联系客服微信</span>
          </div> -->
        </div>
      </div>

      <div class="dv-bottom">
        <div class="brotop">
          <div class="p2">
            © 2021-2022 视联方青（广州）医疗科技有限公司 All Rights Reserved.
          </div>

          <div class="flex-right">
            <div class="ga-dv ga-records">
              <img src="../assets/img/images/ga.png" alt="" />
              <a href="" class="p2">粤公网安备 44010502002210号</a>
            </div>
            <div class="ga-dv">
              <a href="http://beian.miit.gov.cn/" class="p2"
                >粤ICP备2021154174号-1</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { id: 1, title: "首页", path: "/", name: "Home" },
        {
          id: 2,
          title: "远程协同平台",
          path: "/remoteCollaborative",
          name: "RemoteCollaborative"
        },
        { id: 3, title: "解决方案" },
        { id: 4, title: "关于我们", path: "/about", name: "About" }
      ]
    };
  },
  methods: {
    toLink(item) {
      if (item.name === this.$route.name) return;
      this.$router.push(item.path);
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-box {
  padding: 40px 0 30px 0;
  background: #323738;
  .footer-group {
    display: flex;
    flex-direction: column;
  }
  .marb {
    margin-bottom: 15px;
  }
  .dv-top {
    display: flex;
    justify-content: space-between;
    .dv1-box {
      display: flex;
      // padding: 0 10%;
      .dv-item {
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;
      }
      .dv-item-mar {
        margin-right: 30px;
      }
      .dvimg {
        width: 120px;
        height: 120px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      span {
        padding-top: 8px;
        font-size: 14px;
        color: #fff;
      }
    }

    .dv2-box {
      display: flex;
      width: 100%;
      .text-box {
        // width: 50%;
        .title-p {
          color: #fff;
          font-size: 16px;
          padding-bottom: 40px;
        }
        .title-p1 {
          font-size: 14px;
          color: #fff;
          line-height: 20px;
        }
        .title-pdt {
          padding-bottom: 16px;
        }
        .text-p2 {
          font-size: 14px;
          color: #d5d5d5;
        }
      }
    }
  }
  .dv-bottom {
    // padding: 0 22px;
    margin-top: 50px;
    .brotop {
      padding-top: 30px;
      border-top: 1px solid #595959;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .p2 {
        line-height: 20px;
        font-size: 14px;
        color: #fff;
      }
      .flex-right {
        display: flex;
        align-items: center;
      }
      .ga-dv {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 19px;
          height: 19px;
          margin-right: 5px;
        }
      }
      .ga-records {
        padding: 0 30px;
      }
    }
  }
}

.footer-logo-img {
  width: 157px;
  height: 42px;
  img {
    width: 100%;
    height: 100%;
  }
}

.a-link {
  cursor: pointer;
}
.a-link:hover {
  color: #007aff !important;
}
</style>
